export enum CommentVisibility {
  admin,
  published,
  deleted,
  inherit
}
export const AllCommentVisibilities = [
  CommentVisibility.published,
  CommentVisibility.admin,
  CommentVisibility.deleted,
  CommentVisibility.inherit
];

export interface IMediaItem {
  type: 'audio' | 'video';
  // For video type it contains link to vimeo data object not to video file itself
  link: string;
  id: string;
}

export default interface IComment {
  _id: string;
  id?: string;
  author_id: string;
  author_name: string;
  title: string;
  text: string;
  date: Date;
  visibility: CommentVisibility;
  replies: IComment[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chapter: string | any;
  code?: number;
  media?: IMediaItem[];
  responseRecommendation?: string;
}
