<div>
  <div [class]="'comments ' + style">
    <div
      class="content"
      [class.expanded]="true"
    >
      {{ some }}
      <comment
        *ngFor="let comment of comments | async"
        [comment]="comment"
        [editMode]="editMode"
        (onUpdateComments)="loadComments($event)"
        (onSuccessReply)="onSuccessReply()"
        (onErrorReply)="onError($event)"
      ></comment>
      <!-- This should be one line ans when you type it  starts 2 grow -->
      <form [formGroup]="newCommentForm">
        <ng-container *ngIf="requireVideoBeforeCommenting">
          <p class="comments__require-video">
            Bitte schau dir zuerst das Video an, bevor du Fragen an unseren Tutoren stellst.
            In den Videos gehen wir oft schon auf typische Schwierigkeiten ein.
          </p>
        </ng-container>
        <ng-container *ngIf="!requireVideoBeforeCommenting">
          <div class="input">
            <div class="info-wrapper" *ngIf="isAnonymous | async">
            <span class="infoPopover"
            >Registriere dich bitte, um diese Funktion zu nutzen (weiterhin
              kostenlos)</span
            >
            </div>
            <textarea
              class="textarea"
              formControlName="text"
              (input)="autoGrow($event.target)"
              placeholder="Neue Frage hier"
            ></textarea>
            <button
              class="btn outline primary"
              [class.disabled]="isAnonymous | async"
              [disabled]="isAnonymous | async"
              (click)="submit($event)"
            >
              <span class="fa fa-envelope"></span>
            </button>
          </div>
          <div class="help-block" *ngIf="submitted">
            <div
              *ngIf="(f.text?.errors && f.text?.touched)"
              class="invalid-feedback d-block"
            >
              <div
                *ngIf="f.text?.errors?.minlength"
                class="alert alert-danger"
                role="alert"
              >
                Deine Frage ist zu kurz
              </div>
              <div
                *ngIf="f.text?.errors?.required"
                class="alert alert-danger"
                role="alert"
              >
                Bitte teile uns deine Frage mit
              </div>
            </div>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</div>

<app-dialog
  #commentsErrorModal
  [id]="modalsService.MODAL_IDS.COMMENTS_ADD_ERROR"
  [closable]="true"
  [cancelable]="false"
  [submittable]="true"
  [title]="''"
  [submitText]="'Ok'"
  (onSubmit)="modalsService.close(commentsErrorModal)"
>
  <p>{{errorText}}</p>
</app-dialog>


<app-dialog
  #commentsSuccessModal
  [id]="modalsService.MODAL_IDS.COMMENTS_ADD_SUCCESS_SUBMIT"
  [closable]="true"
  [cancelable]="false"
  [submittable]="true"
  [title]="''"
  [submitText]="'Ok'"
>
  <p>
    Danke für deine Frage. Du erhältst eine Email, wenn sie beantwortet ist.
  </p>
</app-dialog>
