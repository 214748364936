<div class="breadcrumbs" [attr.data-test-id]="TEST_IDS.ROOT">
  <div class="breadcrumbs__path">
    <a routerLink="/mycourses" [attr.data-test-id]="TEST_IDS.MAIN_COURSE">Mein Kursbereich </a>

    <ng-container *ngIf="!home && _course && !checkout">
      <a routerLink="/courses/course/{{_course?.url_slug}}">
        <i class="icon fas fa-angle-right"></i>
        {{(course | async)?.title}}
      </a>

      <a
        *ngIf="_column && !showProgress()"
        routerLink="/courses/course/{{_course?.url_slug}}/{{_column.url_slug}}"
      >
        <i class="icon fas fa-angle-right"></i>
        {{_column?.title}}
      </a>

      <a *ngIf="showProgress()">
        <i class="icon fas fa-angle-right"></i>
        Lernübersicht
      </a>
    </ng-container>

    <a *ngIf="checkout">
      <i class="icon fas fa-angle-right"></i>
      Buchen
    </a>
  </div>

  <div class="header">
    <div class="heading">
      <h4 class="breadcrumbs__title" *ngIf="checkout">Buchen</h4>
      <h4 class="breadcrumbs__title" *ngIf="!checkout">{{heading | async}}</h4>
      <h4
        class="breadcrumbs__title"
        *ngIf="!checkout && learningOverview && !(heading | async)"
      >
        Lernübersicht {{_course?.title}}:
      </h4>
      <h4
        class="breadcrumbs__title"
        *ngIf="!checkout && !learningOverview && !(heading | async)"
      >
        Herzlich Willkommen im Kursbereich :)
      </h4>
    </div>
    <div class="meta">
      <div *ngIf="_course && showProgress()" class="total-progress">
        <span class="label">Fortschritt:</span>
        <progress-bar
          [progress]="_course.processedProgress || 0"
        ></progress-bar>
      </div>
    </div>
  </div>
</div>
