/**
 * Created by huck on 15.06.18
 */
import {
  AfterViewInit,
  Component, ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import ISection, {
  ChatbotDisabledBy,
  IIllustrationSize,
  IIllustrationWrapper,
  ICustomSectionStyle,
  ISectionTypeInfo,
  IVideoWrapper,
  SectionImagePosition,
  SectionType,
  SectionTypeInfo
} from '@models/section';
import { animate, style, transition, trigger } from '@angular/animations';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ProgressState } from '@models/profile';
import { LoginService } from '@core/auth/login.service';
import { map } from 'rxjs/operators';
import { ModalsService } from '@components/modals/modals.service';
import { MediaCaptureService } from '@services/media-capture';
import { IVideoExpanded } from '@components/section/components/section/section.types';
import BasicRxComponent from '@components/BasicRxComponent';

@Component({
  selector: 'course-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('showSectionAnimation', [
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        animate('500ms', style({ opacity: 1, height: 'auto' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, height: 'auto' }),
        animate('500ms', style({ opacity: 0, height: 0 }))
      ])
    ]),
    trigger('showVideoAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('500ms', style({ height: 300, opacity: 1 }))
      ]),
      transition(':leave', [
        style({ height: 300, opacity: 1 }),
        animate('500ms', style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class SectionComponent extends BasicRxComponent implements OnInit, AfterViewInit {
  content: SafeHtml;

  classString: string;
  boxClassString: string;

  title: string;

  public innerWidth: number;

  public ImagePosition = SectionImagePosition;
  public images: IIllustrationWrapper[] = [];
  public mobileImages: IIllustrationWrapper[] = [];
  public showSection = false;
  public showMobile = false;
  public sectionTypes = SectionType;
  public expanded = {};
  public info: ISectionTypeInfo;
  public titleDidChange = false;
  public hadVideoExpanded$ = new BehaviorSubject(false);
  public requireVideoBeforeCommenting$ = new BehaviorSubject(false);

  @Input() public section: ISection | null;
  @Input() public editMode = false;
  @Input() public isCommentsAdmin = false;
  @Input() public titlePrefix = '';
  @Input() public chapterId = '';
  @Input() public allowComments = false;
  @Input() public itsAdminMode = false;
  @Input() public progressState: Observable<ProgressState> | null = null;
  @Input() public embeddedView = false;
  @Input() public allowChatbot = false;
  @Input() public isExamColumn = false;
  @Input() public isUpdatingProgress = false;
  @Input() public requireVideoBeforeCommenting = false;
  @Output() public notUnderstood = new EventEmitter<void>();
  @Output() public partlyUnderstood = new EventEmitter<void>();
  @Output() public fullyUnderstood = new EventEmitter<void>();
  @Output() public imagesChanged = new EventEmitter<IIllustrationWrapper[]>();
  @Output() public mobileImagesChanged = new EventEmitter<
    IIllustrationWrapper[]
  >();
  @Output() public videosChanged = new EventEmitter<IVideoWrapper[]>();
  @Output() public chatbotDisabledByChanged = new EventEmitter<ChatbotDisabledBy>();
  @Output() public onCustomStylesChange = new EventEmitter<ICustomSectionStyle[]>();
  @Output() public videoExpanded = new EventEmitter<IVideoExpanded>();

  @Input() changeEvaluation: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);

  @HostBinding('class') get class() {
    switch (this.sectionType) {
      case SectionType.ExamText:
        return this.getHostClass('course-section__exam');
      case SectionType.AdminsOnly:
        return this.getHostClass('course-section__admins-only');
      case SectionType.ExamSolution:
        return this.getHostClass('course-section__solution');
      default:
        return this.getHostClass('');
    }
  }

  public hasVideo() {
    return this.section.videos?.length > 0;
  }

  private getHostClass(modifier: string): string {
    return `course-section ${modifier}`;
  }

  onClickEvaluation(): void {
    this.changeEvaluation.next(false);
  }

  constructor(
    private sanitizer: DomSanitizer,
    public loginService: LoginService,
    public modalsService: ModalsService,
    public mediaCaptureService: MediaCaptureService,
    public el: ElementRef,
  ) {
    super();
  }

  public get isChatbotEnabled() {
    return this.allowChatbot;
  }

  public get sectionType(): SectionType {
    if (!this.itsAdminMode && !this.editMode && this.isExamColumn && this.section.type === SectionType.Latex) {
      return SectionType.ExamSolution;
    }
    return this.section.type;
  }

  public get showChatbotDisabledBy() {
    return this.editMode && this.isExamColumn && this.allowChatbot
      && ![SectionType.AdminsOnly, SectionType.ExamText].includes(this.sectionType);
  }

  public get showChatbotDisabledReason() {
    return this.editMode && this.isExamColumn && this.allowChatbot;
  }

  public get showCustomStyles() {
    return this.editMode && this.isExamColumn && this.sectionType === SectionType.Latex;
  }

  public get customClasses() {
    return (this.section.customStyles || []).filter((style) => style.type === 'class')
      .map((style) => `custom-style-${style.value}`)
  }

  public setChatbotDisabled(value: ChatbotDisabledBy) {
    this.chatbotDisabledByChanged.emit(value);
  }

  ngOnInit(): void {
    this.info = new SectionTypeInfo(this.sectionType);
    this.showSection = !this.info.expandable || this.editMode;
    this.content = this.sanitizer.bypassSecurityTrustHtml(this.section.content);
    this.classString = this.info.classString;
    this.boxClassString = this.info.boxClassString;
    this.innerWidth = window.innerWidth;

    this.showMobile = !!(
      innerWidth < 500 &&
      this.section.mobileImages &&
      this.section.mobileImages.length
    );

    this.title = this.section.hasTitle
      ? this.section.title || this.info.defaultTitle
      : null;

    this.images = this.section.images;
    this.mobileImages = this.section.mobileImages;


    this.bag.add(
      combineLatest([this.loginService.isAdmin, this.hadVideoExpanded$])
        .subscribe(([isAdmin, hadVideoExpanded]) => {
          this.requireVideoBeforeCommenting$.next(
            this.requireVideoBeforeCommenting && !isAdmin && this.hasVideo && !hadVideoExpanded
          );
        }),
    );

    jQuery(function () {
      setTimeout(function () {
        // and here we add the onscroll listener. Yep, kill me pls
        jQuery('.math_block').on('scroll', function (e) {
          // so, we will add a cheat class, that doesn't really change the element, but forces the background to redraw. Afterwards we remove the class a-sync again, so it actually does redraw and it works more than once. Chrome needs a css change in order to redraw
          const el = jQuery(e.target);
          el.toggleClass('scroll-shadow-cheat-kill-me-later-for-this');
        });
      }, 3000); // just fucking kill me
    });
  }

  ngAfterViewInit(): void {
    // @ts-ignore
    window.sectionComp = this;
    //copy protection
    jQuery('img').on('contextmenu', function () {
      return false;
    });
    jQuery('video').on('contextmenu', function () {
      return false;
    });
    $('img').on('dragstart', function (event) {
      event.preventDefault();
    });
    $('video').on('dragstart', function (event) {
      event.preventDefault();
    });
  }

  public toggleShow(): void {
    this.showSection = !this.showSection;
  }

  public toggleExpanded(video: IVideoWrapper): void {
    if (this.expanded.hasOwnProperty(video.public_id)) {
      this.expanded[video.public_id] = !this.expanded[video.public_id];
    } else {
      this.expanded[video.public_id] = true;
    }
    this.videoExpanded.emit({ id: video.public_id, expanded: this.expanded[video.public_id] });
    this.hadVideoExpanded$.next(true);
  }

  public classForImageSize(size: IIllustrationSize): string {
    let sizeClass: string;
    switch (size) {
      case IIllustrationSize.small:
        sizeClass = 'small';
        break;
      case IIllustrationSize.medium:
        sizeClass = 'medium';
        break;
      case IIllustrationSize.large:
        sizeClass = 'large';
        break;
      default:
        console.error('no size class for ' + size);
        sizeClass = 'small';
    }
    return 'polaroid  col-xs ' + sizeClass;
  }

  public updateImages(mobile: boolean): void {
    if (mobile) {
      this.mobileImagesChanged.next(this.mobileImages);
    } else {
      this.imagesChanged.next(this.images);
    }
  }

  public updateVideos(): void {
    this.videosChanged.next(this.section.videos);
  }

  public dropImage(
    wrapperToDelete: IIllustrationWrapper,
    mobile: boolean
  ): void {
    if (mobile) {
      this.mobileImages = this.mobileImages.filter(
        (wr) => wr.url !== wrapperToDelete.url
      );
    } else {
      this.images = this.images.filter((wr) => wr.url !== wrapperToDelete.url);
    }

    this.updateImages(mobile);
  }

  public dropVideo(video: IVideoWrapper): void {
    const keyToCompare = video.public_id !== undefined ? 'public_id' : 'url';

    this.section.videos = this.section.videos.filter(
      (wr) => wr[keyToCompare] !== video[keyToCompare]
    );
    this.updateVideos();
  }

  public titleChanged(): void {
    this.titleDidChange = true;
  }

  public urlForImage(location: string): string {
    return location;
  }

  public get isNotUnderstood(): Observable<boolean> {
    return this.progressState?.pipe(
      map((state) => state == ProgressState.notUnderstood)
    );
  }

  public get isFullyUnderstood(): Observable<boolean> {
    return this.progressState?.pipe(
      map((state) => state == ProgressState.fullyUnderstood)
    );
  }

  public get isPartlyUnderstood(): Observable<boolean> {
    return this.progressState?.pipe(
      map((state) => state == ProgressState.partlyUnderstood)
    );
  }

}
